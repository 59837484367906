<template>
  <div id="dispatchOrder">
    <header-nav :content="`${$t('pages_router_023')} / ${ $t('pages_router_058')}`" />
    <div class="common-card-body">
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_100') }}: </span>
          <a-input
            v-model:value="searchQuery.orderNo"
            allow-clear
            @pressEnter="loadDispatchOrders(1)"
            @blur="loadDispatchOrders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_101') }}: </span>
          <a-select
            v-model:value="searchQuery.businessType"
            show-search
            allow-clear
            @change="loadDispatchOrders(1)"
          >
            <a-select-option
              v-for="opt in businessTypeOpts"
              :key="opt.code"
              :value="opt.code"
            >{{ opt.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_026') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadDispatchOrders(1)"
          >
            <a-select-option
              v-for="status in statusOpts"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_102') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateCreateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadDispatchOrders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_103') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateUpdateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadDispatchOrders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_user_104') }}: </span>
          <a-input
            v-model:value="searchQuery.applyNo"
            allow-clear
            @pressEnter="loadDispatchOrders(1)"
            @blur="loadDispatchOrders(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="orderList"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-button
                v-if="record.status === '04'"
                shape="round"
                size="small"
                class="btn-success"
                @click="$router.push({
                  path: '/user/dispatch_order_edit',
                  query: { id: record.id }
                })"
              >{{ $t('pages_user_106') }}</a-button>

              <a-button
                v-else-if="record.status === '01'"
                shape="round"
                size="small"
                type="primary"
                @click="$router.push({
                  path: '/user/dispatch_order_edit',
                  query: { id: record.id }
                })"
              >{{ $t('pages_user_105') }}</a-button>

              <a-button
                v-else
                shape="round"
                size="small"
                class="btn-primary-empty-0"
                @click="$router.push({
                  path: '/user/dispatch_order_edit',
                  query: { id: record.id }
                })"
              >{{ $t('pages_user_107') }}</a-button>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import { _user } from '@/api/index'
import i18n from '@/locale/index'
import { getAttrStatusList, getTradeStatusName, parseMoney } from '@/util'
export default {
  name: 'DispatchOrder',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage
  },
  setup() {
    const state = reactive({
      loading: true,
      total: 0,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        dateCreateOpts: [],
        dateUpdateOpts: []
      },
      orderList: [],
      columns: [
        { key: 'orderNo', dataIndex: 'orderNo', title: i18n.global.t('pages_user_100'), width: 200, ellipsis: true },
        { key: 'businessType', dataIndex: 'businessTypeText', title: i18n.global.t('pages_user_101'), width: 150 },
        { key: 'applyNo', dataIndex: 'applyNo', title: i18n.global.t('pages_user_104'), width: 200, ellipsis: true },
        { key: 'receiveAmount', dataIndex: 'receiveAmountText', title: i18n.global.t('pages_user_108'), width: 180 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_user_102'), width: 180 },
        { key: 'updateTime', dataIndex: 'updateTime', title: i18n.global.t('pages_user_103'), width: 180 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_user_026'), width: 150 },
        { key: 'remark', dataIndex: 'remark', title: i18n.global.t('pages_user_109'), width: 200, ellipsis: true },
        { key: 'message', dataIndex: 'message', title: i18n.global.t('pages_user_110'), width: 200, ellipsis: true },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: 120 }
      ]
    })
    state.businessTypeOpts = computed(() => {
      return getAttrStatusList('dispatchOrderBusinessType')
    })
    state.statusOpts = computed(() => {
      return getAttrStatusList('dispatchStatus')
    })
    const init = () => {
      loadDispatchOrders()
    }
    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadDispatchOrders()
    }
    const loadDispatchOrders = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateCreateOpts) state.searchQuery.dateCreateOpts = []
      if (!state.searchQuery.dateUpdateOpts) state.searchQuery.dateUpdateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateCreateOpts, dateUpdateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        startCreateTimeStr: dateCreateOpts[0] ? `${dateCreateOpts[0]} 00:00:00` : '',
        endCreateTimeStr: dateCreateOpts[1] ? `${dateCreateOpts[1]} 23:59:59` : '',
        startUpdateTimeStr: dateUpdateOpts[0] ? `${dateUpdateOpts[0]} 00:00:00` : '',
        endUpdateTimeStr: dateUpdateOpts[1] ? `${dateUpdateOpts[1]} 23:59:59` : ''
      })
      const res = await _user.getDispatchList(query)
      if (res.data.succ) {
        const { data } = res.data
        state.orderList = data.list.map(item => {
          return Object.assign({}, item, {
            receiveAmountText: `${item.currency} ${parseMoney(item.receiveAmount, item.currency)}`,
            statusText: getTradeStatusName(item.status, 'dispatchStatus'),
            businessTypeText: getTradeStatusName(item.businessType, 'dispatchOrderBusinessType')
          })
        })
        state.total = data.total
      }
      state.loading = false
    }
    init()
    return {
      getAttrStatusList,
      updatePage,
      loadDispatchOrders,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#dispatchOrder {
  .common-card-body {
    .search-form {
      .part-label {
        width: 158px;
      }
    }
    .table-part {
      margin-top: 12px;
    }
  }
}
</style>

